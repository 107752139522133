import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab5d5146"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reportProp" }
const _hoisted_2 = { class: "expBoday" }
const _hoisted_3 = { class: "exp_title" }
const _hoisted_4 = { class: "page" }

import axios from "../api/axios";
import { reactive,ref, watch,useContext} from "vue";
import { useRoute } from 'vue-router'
import { ElMessage } from "element-plus";



export default {
  props: {
    clockIds:Number,
    propName:String,
    isShowClock:Boolean,
},
  setup(__props) {

const prop = __props

const token = localStorage.getItem("token");
const department = JSON.parse(localStorage.getItem("userInfo"))
const state = reactive({
  isShowClock:false,
  productInfo:{},
  checkList:[],
  pageSum:{},
  page:1,

});




// 监听父组件点击传的值
watch(()=>[prop.clockIds,prop.isShowClock],(newId,oldId)=>{
    
    state.isShowClock = true
    getAuditInfo(prop.clockIds,state.page)
})



const getAuditInfo = (id,page) =>{
    axios({
        method:'POST',
        url:"cust/clock/info",
        data:{token,id,page},
    }).then((data)=>{
       if(data.data.status == 1){
           state.checkList = data.data.data.company;
           state.pageSum = data.data.data.pageSum;
       }
    });
}
const currentChange = (val) =>{
    console.log(val);
    state.page = val;
    getAuditInfo(prop.clockIds,state.page)
}




return (_ctx, _cache) => {
  const _component_el_pagination = _resolveComponent("el-pagination")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: _unref(state).isShowClock,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_unref(state).isShowClock) = $event)),
      title: _unref(prop).propName,
      "before-close": _ctx.handleClose
    }, {
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).checkList, (item, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(item), 1)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_unref(state).pageSum)
            ? (_openBlock(), _createBlock(_component_el_pagination, {
                key: 0,
                background: "",
                layout: "prev, pager, next, jumper",
                total: _unref(state).pageSum.last_page*10||10,
                onCurrentChange: currentChange,
                currentPage: _ctx.currentPage,
                "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => ((_ctx.currentPage) = $event)),
                class: "flex_r"
              }, null, 8, ["total", "currentPage"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "before-close"])
  ]))
}
}

}